:root {
  --primary-color: #640FF5;
  --primary-color-hover: #4200b0;
  --background: white;
  --text-primary: #172b4d;
  --text-secondary: #7a7a7a;
  --text-faint: #a9a9a9;
  --accent: purple;

  --link-color: #156aff;

  --topbar-background-color: white;
  --kanban-background-color: #fafbfd;

  --auth-background-image: url("./images/LoginBG2.jpg");

  --tabbar-border-top: none;

  /* Card stuff */
  --card-background-color: white;
  --card-border-color: #EFEFEF;
  --card-box-shadow: 0 1px 1px #efefef;

  --border-color: #ededed;

  --actively-selected-border-color: #c8d9ff;

  --time-estimate-background: #f5f5f5;
  --time-estimate-color: #585858;

  --topbar-button-third-color: #b9b9b9;
  --topbar-button-secondary-color: #444444;
  --topbar-button-primary-color: #000000;

  --topbar-button-hover-background-color: #efefef;
  --topbar-button-background-color-filled: white;

  --topbar-border-bottom: 1px solid #7070701a;
  --sidebar-border: 1px solid #7070701a;

  --calendar-background-color: white;
  --calendar-border-color: #ddd;
  --calendar-event-border-color: #d1d1d1;
  --calendar-text-color: #3c4043;
  --calendar-evnt-box-shadow-color: white;
  --calendar-sidebar-time-color: #898989;
  --calendar-header-color: #676767;

  --placeholder-color: #dadce0;

  --calendar-response-button-background-color: #f5efff;

  --option-hover-background-color: #ededed;
  --option-border-color: #dedede;

  --complete-button-border: 1.5px solid #d3d3d3;
  --complete-button-completed-background: #00bb3a;

  /* Controls */
  --switch-option-active-background: #fff;
  --switch-option-active-color: #131313;
  --switch-option-color: rgb(106 106 106 / 88%);
  --switch-background: #f5f5f5;
  --switch-active-box-shadow: 0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%);

  --switch-hover-background: #e9e9e9;
  --switch-hover-color: #595959;

  /* Modal */
  --modal-background-color: #ffffff;
  --modal-overlay-background: none;

  --modal-property-title-color: rgba(55, 53, 47, 0.65);
  --modal-property-title-icon: rgba(55, 53, 47, 0.45);

  --modal-property-value-color: #494949;
  --modal-property-hover-color: #37352f14;
  --modal-property-value-empty-color: #a5a5a5;

  --modal-section-header: #3c3c3c;

  --modal-textarea-hover-color: #f0f0f0;
  --modal-section-divider: #eaeaea;

  --subtask-background-color: #f5f5f5;
  --subtask-background-color-hover: #f0f0f0;

  /* Settings */
  --settings-sidebar-background-color: rgb(251, 251, 251);
  --settings-sidebar-border-right: 1px solid rgb(238, 238, 238);
  --settings-topbar-background-color: rgb(251, 251, 251);
  --settings-topbar-border-bottom: 1px solid #e7e7e7;

  --settings-sidebar-item-color: #303030;
  --settings-sidebar-item-color-hover: #000000;
  --settings-sidebar-item-background-hover: #efefef;
  --settings-sidebar-icon: #a7a7a7;
  --settings-row-divider: #dcdce0;

  --button-color: #404040;
  --button-background-color: #ffffff;
  --button-border: 1px solid #d5d5d5;
  --button-hover-color: #9100c1;
  --button-hover-background-color: #fcf6ff;
  --button-hover-border: 1px solid #d3b2ff;

  --primary-button-color: #ffffff;

  --settings-input-background: #ffffff;
  --settings-input-border: 1px solid #cacaca;
  --settings-input-hover-border: 1px solid #000000;
  --settings-input-color: #323232;

  --close-icon-background: #f4f4f4;
  --close-icon-background-2: #ededed;
  --close-icon-color: #bcbcbc;
  --close-icon-color-2: #7a7a7a;
  --close-icon-hover-background: #e7e7e7;
  --close-icon-hover-background-2: #e7e7e7;
  --close-icon-hover-color: #ffffff;

  --big-button-border: #f4f4f4;

  --destroy-button-background: #ffffff;
  --destroy-button-color: #ff1f1f;
  --destroy-button-border: 1px solid #ff9a9a;

  --label-editable-background: #FBFBFC;
  --label-editable-action-menu-hover-background: #f1f1f1;
  --search-bar-background: #ffffff;
  --search-bar-border: 1px solid #e1e1e1;

  --message-background: #f6f6f6;
  --message-color: #545454;

  --check-color: #696969;
  --modal-gradient: linear-gradient(rgb(245 107 14 / 13%) 0%, rgba(15, 255, 175, 0) 70%);

  /* Analytics */
  --filter-bar-button-border: 1px solid #e4e4e4;
  --green-chip-background: rgb(220 252 231);
  --green-chip-color: rgb(22 101 52);
  --divider-color: #e5e5e5;
  --range-option-selected-background: #e6e6e6;

  /* Timer */
  --timer-background: #202020;
  --timer-primary-color: #ffffff;
  --timer-control-hover-background: #f0f0f0;
  --timer-control-hover-color: #111111;

  --pro-feature-background: #f2f0ff;
  --pro-feature-border: #e4e0fd;

  --download-container-background: #f6f6f6;
  --download-container-border: #eaeaea;
  --download-button-background: #d7d7d7;
  --download-button-background-hover: #e0dfff;

  --scrollbar-track: #f0f0f0;
  --scrollbar-thumb: #ccc;
  --scrollbar-thumb-hover: #999;

  --big-button-background: #ffffff;
  --big-button-border-color: #dcdcdc;
  --big-button-background-hover: #f4f4f4;

  --integration-card--background: #f6f6f6;

  --suggestions-mention-background: #ffffff;
  --suggestions-mention-focused-background: #e9e9e9;
  --suggestions-mention-border: 1px solid #e9e9e9;

  --label-mention-background: #ffd9bf;
  --date-mention-background: #e1bfff;
  --duration-mention-background: #ffe8bf;
  --list-mention-background: #bfe4ff;

  color-scheme: light;
}


[data-theme='dark'] {
  --primary-color: #9a5fff;
  --primary-color-hover: #7a2ffb;
  --background: #121314;
  --text-primary: white;
  --text-secondary: #767676;
  --text-faint: #7a7a7a;
  --accent: darkred;
  --topbar-background-color: #141415;
  --kanban-background-color: #141415;
  --link-color: #156aff;

  --auth-background-image: url("./images/LoginBGDark.jpg");

  --tabbar-border-top: 1px solid #222222;

  /* Card */
  --card-background-color: #282828;
  --card-border-color: #282828;
  --card-box-shadow: none;
  --time-estimate-background: #3f3f3f;
  --time-estimate-color: #d1d1d1;

  --border-color: #393939;
  --actively-selected-border-color: #606060;

  --topbar-button-third-color: #979797;
  --topbar-button-secondary-color: #b4b4b4;
  --topbar-button-primary-color: #ffffff;
  --topbar-button-hover-background-color: #313131;
  --topbar-button-background-color-filled: transparent;

  --topbar-button-border: 1px solid #494949;

  --topbar-border-bottom: 1px solid #323232de;
  --sidebar-border: 1px solid #272727de;


  --calendar-background-color: #262626;
  --calendar-border-color: #525252;
  --calendar-event-border-color: #d1d1d1;
  --calendar-text-color: #c9c9c9;
  --calendar-evnt-box-shadow-color: #262626;
  --calendar-sidebar-time-color: #797979;
  --calendar-header-color: #a0a0a0;

  --placeholder-color: #5a5a5a;

  --calendar-response-button-background-color: #1f1f1f;

  --option-hover-background-color: #3a3a3a;
  --option-border-color: #676767;

  --complete-button-border: 1.5px solid #979797;
  --complete-button-completed-background: #00922d;

  /* Controls */
  --switch-option-active-background: #1f1f1f;
  --switch-option-active-color: #ffffff;
  --switch-option-color: #ffffffe0;
  --switch-background: #4b4b4b;
  --switch-active-box-shadow: 0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%);

  --switch-hover-background: #232323;

  --switch-hover-color: #c3c3c3;

  /* Modal */
  --modal-background-color: #1f1f1f;
  --modal-overlay-background: rgba(0, 0, 0, 0.45);

  --modal-property-title-color: #ffffff71;
  --modal-property-title-icon: #ffffff71;
  --modal-property-value-color: rgba(255, 255, 255, 0.81);
  --modal-property-value-empty-color: rgba(255, 255, 255, 0.282);

  --modal-property-hover-color: #ffffff0e;
  --modal-textarea-hover-color: #373737;
  --modal-section-divider: #ffffff18;

  --modal-section-header: #828282;

  --subtask-background-color: #f5f5f5;
  --subtask-background-color-hover: #272727;

  /* Settings */
  --settings-sidebar-background-color: #242526;
  --settings-sidebar-border-right: 1px solid rgb(56, 56, 56);
  --settings-topbar-background-color: rgb(41, 42, 43);
  --settings-topbar-border-bottom: 1px solid rgb(56, 56, 56);

  --settings-sidebar-item-color: #e2e2e2;
  --settings-sidebar-item-color-hover: #ffffff;
  --settings-sidebar-item-background-hover: #3d3d3d;
  --settings-sidebar-icon: #d7d7d7;
  --settings-row-divider: #3e3e3e;

  --button-color: #bcbcbc;
  --button-background-color: #1f1f1f;
  --button-border: 1px solid #373737;
  --button-hover-color: #d868fd;
  --button-hover-background-color: #222222;
  --button-hover-border: 1px solid #d868fd;

  --primary-button-color: #ffffff;

  --settings-input-background: #2d2d2d;
  --settings-input-border: 1px solid #454545;
  --settings-input-hover-border: 1px solid #a6a6a6;
  --settings-input-color: #ffffff;

  --close-icon-background: #4c4c4c;
  --close-icon-background-2: #4c4c4c;
  --close-icon-color: #bcbcbc;
  --close-icon-color-2: #bcbcbc;
  --close-icon-hover-background: #333333;
  --close-icon-hover-background-2: #333333;
  --close-icon-hover-color: #ffffff;

  --big-button-border: #434343;

  --destroy-button-background: #202020;
  --destroy-button-color: #ff6565;
  --destroy-button-border: 1px solid #ff4c4c;

  --label-editable-background: #262626;
  --label-editable-action-menu-hover-background: #3c3c3c;

  --search-bar-background: #282828;
  --search-bar-border: 1px solid #444444;
  --search-bar-border-hover: 1px solid #a6a6a6;

  /* Upgrade modal */
  --message-background: #262626;
  --message-color: #545454;

  --check-color: #dcdcdc;
  --modal-gradient: linear-gradient(rgb(141 14 245 / 17%) 0%, rgba(15, 255, 175, 0) 70%);

  /* Analytics */
  --filter-bar-button-border: 1px solid #494949;
  --green-chip-background: rgb(0 129 44);
  --green-chip-color: rgb(248 248 248);
  --divider-color: #4b4b4b;
  --range-option-selected-background: #646464;

  /* Timer */
  --timer-background: #ffffff;
  --timer-primary-color: #000000;
  --timer-control-hover-background: #cacaca;
  --timer-control-hover-color: #111111;

  --pro-feature-background: #0f0f0f;
  --pro-feature-border: #0f0f0f;

  --download-container-background: #2d2d2d;
  --download-container-border: #333333;
  --download-button-background: #434343;
  --download-button-background-hover: #181818;

  --scrollbar-track: #2b2b2b;
  --scrollbar-thumb: #555;
  --scrollbar-thumb-hover: #888;

  --big-button-background: #1f1f1f;
  --big-button-border-color: #515151;
  --big-button-background-hover: #303030;

  --suggestions-mention-background: #181818;
  --suggestions-mention-focused-background: #282828;
  --suggestions-mention-border: 1px solid #2a2a2a;

  --label-mention-background: #993e00;
  --date-mention-background: #6300ba;
  --duration-mention-background: #b87600;
  --list-mention-background: #0079d2;



  color-scheme: dark;
}

[data-theme='light'] {}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  background-color: var(--kanban-background-color);
  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
}

/* Ant Overrides */

.ant-popover-inner {
  border-radius: 8px;
  padding-top: 0px;
  padding-bottom: 4px;

  color: rgb(31, 34, 37);
  text-align: left;
  user-select: none;
  white-space: nowrap;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 10px 20px;
  overflow: hidden;
}

.ant-popover-inner-content {
  border-radius: 8px;
  padding-top: 0px;
  padding-bottom: 4px;

  color: rgb(31, 34, 37);
  text-align: left;
  user-select: none;
  white-space: nowrap;

  overflow: hidden;
}



.app-container {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol"
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  border-radius: 6px;
}

.mobile-container {
  height: 100vh;
}

.mobile-content {}

.mobile-settings-page {
  padding: 25px;
  background-color: var(--background);
  border-radius: 5px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
}

.tabbar-container {
  position: fixed;
  bottom: 0px;
  z-index: 89;
  width: 100%;
}

.floating-buttons-mobile {
  position: fixed;
  bottom: 100px;
  z-index: 89;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  right: 25px;
  -webkit-justify-content: flex-end;
  align-items: flex-end;
  -webkit-align-items: flex-end;
}

.floating-button-mobile {
  background-color: #000000;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.floating-button-mobile-icon {
  height: 20px;
  width: 20px;
}

.floating-button-mobile.filter {
  margin-right: 10px;
  background-color: #ffffff;
  color: #2e2e2e;
  border: 2px solid #adadad;
}

:root {
  --white: #FFF;
  --gray10: #FCFCFC;
  --gray30: #F7F7F7;
  --gray50: #F2F2F2;
  --gray70: #EDEDED;
  --gray100: #E5E5E5;
  --gray150: #D9D9D9;
  --gray200: #CCCCCC;
  --gray250: #BFBFBF;
  --gray300: #B2B2B2;
  --gray350: #A6A6A6;
  --gray400: #999999;
  --gray450: #8C8C8C;
  --gray500: #808080;
  --gray550: #737373;
  --gray600: #666666;
  --gray650: #595959;
  --gray700: #4D4D4D;
  --gray750: #404040;
  --gray800: #333333;
  --gray850: #262626;
  --gray870: #1F1F1F;
  --gray900: #1A1A1A;
  --black: #000;
  --semantic-red100: #F8C4C7;
  --semantic-red300: #F18392;
  --semantic-red600: #E4253B;
  --semantic-red500: #ED4556;
  --semantic-red700: #CC1E36;
  --semantic-red800: #AD1B2A;
  --semantic-red900: #95040E;
  --semantic-yellow100: #FFE8B2;
  --semantic-yellow500: #F3BD3F;
  --semantic-yellow700: #C79623;
  --semantic-yellow900: #8F6400;
  --semantic-green100: #CFE8C9;
  --semantic-green500: #4DB238;
  --semantic-green700: #3E8131;
  --semantic-green900: #166B06;
  --primary100: #FFCDB3;
  --primary200: #FAAF89;
  --primary300: #FA9161;
  --primary400: #FB6B2D;
  --primary500: #FF4800;
  --primary600: #EF4001;
  --primary700: #DA3B07;
  --primary800: #AA330F;
  --primary900: #8B3118;
  --primary500-gradient: linear-gradient(#FF5C0A, #F54100);
  --secondary-semantic-red: var(--semantic-red700);
  --text-default: var(--gray900);
  --text-default-subdued: var(--gray750);
  --text-body: var(--gray600);
  --text-placeholder: var(--gray300);
  --text-active: var(--black);
  --text-detail: var(--gray400);
  --text-underline: var(--gray250);
  --text-contr-default: var(--gray100);
  --text-contr-highlight-default: var(--white);
  --text-contr-detail: var(--gray400);
  --text-contr-highlight-detail: var(--gray300);
  --text-caret: var(--primary600);
  --text-selection: var(--primary100);
  --text-contr-caret: var(--primary400);
  --text-contr-selection: var(--primary900);
  --object-default: var(--gray400);
  --object-strong: var(--gray800);
  --object-placeholder: var(--gray300);
  --object-highlight: var(--gray900);
  --object-active: var(--black);
  --object-border: var(--gray100);
  --object-highlight-border: var(--gray150);
  --object-highlight-bg: var(--gray70);
  --object-highlight-bg-emphasized: var(--gray100);
  --object-highlight-bg-active: var(--gray100);
  --object-contr-default: var(--gray300);
  --object-contr-highlight: var(--gray50);
  --object-contr-active: var(--white);
  --object-contr-highlight-border: var(--gray650);
  --object-contr-highlight-bg: var(--gray700);
  --object-gradient-bg: linear-gradient(var(--gray10), #FAFAFA);
  --layout-line-divider: #F0F0F0;
  --layout-line-divider-elevated: var(--layout-line-divider);
  --layout-line-divider-base: #EBEBEB;
  --layout-line-grid-v: #F0F0F0;
  --layout-line-grid-h: var(--gray30);
  --layout-line-grid-v-wknd: #F0F0F0;
  --layout-line-grid-h-wknd: #F5F5F5;
  --layout-fill-grid: var(--white);
  --layout-fill-grid-wknd: var(--gray10);
  --material-inset: var(--gray70);
  --material-base: var(--gray30);
  --material-base-dimmed: var(--gray10);
  --material-default: var(--white);
  --material-default-elevated: var(--material-default);
  --material-default-to-base: radial-gradient(50% 66.67% at 50% 33.33%, var(--material-default), var(--material-base));
  --material-contrast: linear-gradient(#383838 0%, #2E2E2E 75%);
  --material-dim: rgba(0, 0, 0, 0.08);
  --material-border-inside: rgba(0, 0, 0, 0.1);
  --material-border-contr-inside: rgba(255, 255, 255, 0.12);
  --elevation-low: 0px 1px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
  --elevation-low-material-border-inside: var(--elevation-low), inset 0px 0px 0px 0.5px var(--material-border-inside);
  --elevation-low-material-border-contr-inside: var(--elevation-low), inset 0px 0px 0px 0.5px var(--material-border-contr-inside);
  --elevation-middle: 0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 4px 8px 0px rgba(0, 0, 0, 0.08), 0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 16px 32px 0px rgba(0, 0, 0, 0.08);
  --elevation-middle-material-border-inside: var(--elevation-middle), inset 0px 0px 0px 0.5px var(--material-border-inside);
  --elevation-middle-material-border-contr-inside: var(--elevation-middle), inset 0px 0px 0px 0.5px var(--material-border-contr-inside);
  --elevation-high: 0px 2px 4px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 16px 32px 0px rgba(0, 0, 0, 0.1), 0px 32px 64px 0px rgba(0, 0, 0, 0.12);
  --elevation-high-material-border-inside: var(--elevation-high), inset 0px 0px 0px 0.5px var(--material-border-inside);
  --elevation-high-material-border-contr-inside: var(--elevation-high), inset 0px 0px 0px 0.5px var(--material-border-contr-inside);
  --highlight-light: inset 0px -0.5px 0px 0px rgba(0, 0, 0, 0.1), inset 0px 0.5px 0px 0px rgba(255, 255, 255, 0.25);
  --highlight-focus: 0px 0px 0px 1px var(--primary600), 0px 0px 0px 4px var(--primary100);
  --highlight-contr-focus: 0px 0px 0px 1px var(--primary400), 0px 0px 0px 4px var(--primary900);
  --highlight-cursor: 0px 1px 2.6px 0px rgba(0, 0, 0, 0.32);
  --grid-selection-fill-opacity: 0.125;
  --grid-selection-border-opacity: 0.25;
  --ghost-opacity: 0.5;
  --hidden-opacity: 0.25;
  --dimmed-opacity: var(--hidden-opacity);
  --disabled-opacity: 0.33;
  --disabled-destructive-opacity: 0.5;
  --icon-secondary-opacity: 0.5;
  --date-flash-indicator-opacity: 0.33;
  --radius-s: 2px;
  --radius-m: 4px;
  --radius-l: 8px;
  --context-panel-width: 256px;
  --time-column-padding-right: 6px;
  --time-column-left-gutter-width: 20px;
  --chip-min-height: 10px;
  --chip-ribbon-width: 8px;
  --chip-ribbon-gap: 3px;
  --chip-title-font-size: 11px;
  --chip-title-line-height: 13px;
  --chip-time-margin-top: 2px;
  --chip-time-font-size: 9px;
  --chip-time-line-height: 11px;
  --chip-margin-bottom: 3px;
  --chip-margin-right: 13px;
  --chip-padding-top: 2px;
  --chip-padding-bottom: 1px;
  --chip-padding: var(--chip-padding-top) 4px var(--chip-padding-bottom) 8px;
  --time-indicator: var(--black);
  --time-indicator-bg: var(--white);
  --input-border: inset 0px 0px 0px 1px var(--object-border);
  --input-error-border: inset 0px 0px 0px 1px var(--semantic-red500);
  --input-highlight-border: inset 0px 0px 0px 1px var(--object-highlight-border);
  --input-highlight-bg: var(--object-highlight-bg);
  --input-font-size: 11px;
  --input-line-height: 16px;
  --input-letter-spacing: 0.01em;
  --kbs-bg: rgba(0, 0, 0, 0.07);
  --kbs: rgba(0, 0, 0, 0.45);
  --contr-kbs-bg: rgba(255, 255, 255, 0.2);
  --contr-kbs: rgba(255, 255, 255, 0.8);
  --sans-serif-font: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Helvetica, Arial, sans-serif;
  --compact-font: SF Compact, ".SF Compact", SF Compact Text, ".SF Compact Text", var(--sans-serif-font);
  --monospace-font: ui-monospace, SF Mono, SFMono-Regular, Menlo, Lucida Console, Courier New, Courier, monospace;
  --ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  --ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  --ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  --ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  --ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
  --ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
  --ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
  --ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
  --ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
  --ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
  --ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  --ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
  --ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
  --ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
  --ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
  --ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  --animation-duration-fast: 0.1s;
  --animation-duration-default: 0.175s;
  --animation-duration-long: 0.275s;
  --animation-duration-xlong: 0.875s;
  --animation-duration-context-panel: 0.3s;
  --animation-duration-pulsate: 1.5s;
  --animation-duration-toast-fade-out: 2s;
  --animation-duration-date-flash: 3s;
  --animation-easing-default: var(--ease-out-quad);
  --animation-delay-default: 0.05s;
  --animation-scale-initial-default: 0.95;
  --animation-scale-exit-default: 0.975;
  --z-index-grid-main: 1;
  --z-index-grid-foreground: 200;
  --z-index-grid-hidden-zone: 301;
  --z-index-grid-cursor-indicator: 302;
  --z-index-grid-cursor: 303;
  --z-index-grid-time-indicator: 304;
  --z-index-grid-selected-item: 305;
  --z-index-grid-col-header: 306;
  --z-index-grid-period-length-selector: 307;
  --z-index-grid-all-day: 308;
  --z-index-grid-all-day-cursor-indicator: 309;
  --z-index-grid-all-day-cursor: 310;
  --z-index-grid-time-col: 311;
  --z-index-grid-time-col-header: 312;
  --z-index-grid-bounding-box-selection: 313;
  --z-index-calendar-header: 400;
  --z-index-popover: 401;
  --z-index-toast: 402;
  --z-index-modal: 403;
  --z-index-tooltip: 404;
  --z-index-traffic-light: 405;
  --notifications-window-padding: 16px;
  --notifications-window-padding-bottom: 24px;
  --notification-width: 400px;
  --notification-height: 68px;
  --notification-spacing: 8px;
  --notification-radius: 10px;
  --notification-button-radius: 5px;
  --notification-dismiss-duration-safe: 1s;
}

.ant-popover .ant-popover-inner {
  padding: 0px;
  background-color: var(--modal-background-color);
}

.ant-modal .ant-modal-content {
  padding: 0px;
}

[class^="ant-popover"],
[class*=" ant-popover"] {
  font-size: inherit;
  font-family: inherit;
}

.ant-switch.ant-switch-small {
  background: #b9b9b9;
}

.ant-switch {
  background: #b9b9b9;
}

.ant-modal-wrap {
  background: var(--modal-overlay-background);
}

.update-avaialble-card {
  background: #414141;
  border-radius: 10px;
  padding: 10px 16px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 10000;
  color: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.update-avaialble-card .title {
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.update-avaialble-card .message {
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.update-button {
  background: #6b6b6b;
  /* border: 1px solid #b9b9b9; */
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  /* display: inline-block; */
  /* align-items: flex-start; */
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.update-button:hover {
  background: #b9b9b9;
  color: #414141;
}

.update-avaialble-card .icon {
  display: block;
  vertical-align: middle;
  color: #ffe200;
  height: 13px;
  width: 13px;
}

.today-focus-container {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  margin: auto;
  column-gap: 10px;
  -webkit-user-select: none;
  -webkit-app-region: drag;
}

.today-mode-exit-button {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 50px;
  font-size: 13px;
  font-weight: 500;
  color: var(--button-color);
  cursor: pointer;
  background-color: var(--button-background-color);
  border-radius: 6px;
  padding: 5px 11px;
  display: inline-block;
  border: var(--button-border);
  -webkit-app-region: no-drag;
  z-index: 10000;
}

@media (max-width: 1070px) {
  .today-mode-exit-button {
    font-size: 0;
    width: 30px;
    height: 30px;
    top: 15px;
    right: 15px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .today-mode-exit-button::before,
  .today-mode-exit-button::after {
    content: "";
    position: absolute;
    width: 14px;
    height: 2px;
    background-color: var(--button-color);
  }

  .today-mode-exit-button::before {
    transform: rotate(45deg);
  }

  .today-mode-exit-button::after {
    transform: rotate(-45deg);
  }
}

.today-mode-exit-button:hover {
  color: var(--button-hover-color);
  background-color: var(--button-hover-background-color);
  border: var(--button-hover-border);
}

.electron-draggable {
  display: flex;
  flex-direction: row;
  width: 100%;
  -webkit-user-select: none;
  -webkit-app-region: drag;
}

.electron-draggable.drag-disabled {
  -webkit-app-region: no-drag;
}

/*
/* Customize the scrollbar */
/* For WebKit-based browsers (Chrome, Safari, and Chromium-based Edge) */


::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

::-webkit-scrollbar-thumb:active,
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar:active::-webkit-scrollbar-thumb {
  opacity: 1;
}

/*
html.todesktop .sidebar-logo {
  display: none;
} */

.floating-timer-background {
  -webkit-app-region: drag;
}

.ant-modal-root .ant-modal-mask {
  -webkit-app-region: no-drag;
}

.ant-popover {
  -webkit-app-region: no-drag;
}

.mini-electron-topbar {
  background-color: var(--topbar-background-color);
  height: 35px;
  border-bottom: var(--topbar-border-bottom);
  -webkit-app-region: drag;
}
