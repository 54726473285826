.sidebar-right {
  width: 290px;
  min-width: 290px;
  max-width: 290px;
  background-color: var(--calendar-background-color);
  /* box-shadow: 0px 1px 3px 0px rgb(18 18 18 / 25%); */
  z-index: 500;
  order: 2;
  border-left: var(--sidebar-border);
}

.sidebar-right.today-mode {
  border-left: none;
  background-color: transparent;
  -webkit-app-region: no-drag;
}

.sidebar-right.today-mode.longer {
  width: 400px;
  min-width: 400px;
  max-width: 400px;
}

.sidebar-toggle-right {
  position: absolute;
  z-index: 1000;
  height: 100vh;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #d1d1d1;
  right: 0;
}

.sidebar-toggle-right:hover {
  color: #000000;
}

.sidebar-toggle-icon {
  height: 30px;
}

.mini-calendar-close {
  height: 20px;
  width: 20px;
}

.mini-calendar-right {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  -webkit-app-region: no-drag;
}

.sidebar-right-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 10px 10px 0px 5px;
}

.sidebar-right-container.ritual-mode {
  height: calc(100vh - 105px);
}

.mini-calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 14px 0px 14px;
  border-bottom: var(--topbar-border-bottom);
  margin-left: -10px;
  margin-right: -10px;
  /* margin-top: 13px; */
  height: 55px;
  margin-top: -10px;
  -webkit-user-select: none;
  -webkit-app-region: drag;
}

.mini-toolbar-calendar {
  display: flex;
  align-items: center;
  column-gap: 5px;
  -webkit-app-region: no-drag;
}

.mini-toolbar-calendar.expanded {
  justify-content: space-between;
  width: 100%;
}

.mini-kanban-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 14px 0px 14px;
  border-bottom: var(--topbar-border-bottom);
  /* margin-left: -10px; */
  /* margin-right: -10px; */
  /* margin-top: 13px; */
  min-height: 55px;
  /* margin-top: -10px; */
  background: var(--topbar-background-color);
  -webkit-user-select: none;
  -webkit-app-region: drag;
}

.mini-calendar-container {
  flex: 1 1 auto;
  width: 100%;
  border-bottom: 0.5px solid var(--calendar-border-color);
}

/* if mobile */
@media (max-width: 768px) {
  .mini-calendar-container {
    flex: 4 1 auto;
    width: 100%;
    border-bottom: 0.5px solid var(--calendar-border-color);
  }
}

.mini-cal-title {
  display: flex;
  align-items: baseline;
  width: 100%;
  flex-direction: row;
}

.mini-cal-date {
  font-weight: 600;
  font-size: 1.1em;
  color: var(--text-primary);
  margin-right: 5px;
}

.mini-cal-subdate {
  font-weight: 600;
  font-size: 15px;
  color: var(--text-secondary);
  margin-right: 5px;
}

.mini-calendar-nav {
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: #505050;
}

.topbar-button.today-button {
  margin: 0px 5px;
}

.topbar-button.nav-button {
  height: 25px;
  width: 18px;
  cursor: pointer;
  border-radius: 3px;
  padding: 0px;
  box-shadow: none;
  /* padding: 3px 9px; */
  color: var(--topbar-button-third-color);
  border: none;
}

.topbar-button.nav-button:hover {
  color: var(--topbar-button-primary-color);
}

.fc-timegrid-event-harness-inset .fc-timegrid-event {
  --ribbon-width: 4px;
  --ribbon-spacing: 1px;
  --z-index-background: 1;
  --z-index-ribbon: 2;
  --z-index-highlight-overlay: 3;
  --z-index-content: 3;
  --z-index-resize-handle: 5;
  --resize-handle-height: 4px;
  --resize-handle-width: 4px;
  --highlight-overlay-start-opacity: 0.35;
  --highlight-overlay-end-opacity: 0.25;
  --highlight-overlay-start-opacity-dark: 0.04;
  --highlight-overlay-end-opacity-dark: 0.03;
  position: absolute;
  border-color: var(--border-color);
  border-radius: var(--radius-m);
  border-width: 1px;
  padding: 2px 4px 1px 6px;
  opacity: 1;
  --background-color: rgb(255, 227, 200);
  --foreground-color-primary: rgb(163, 48, 0);
  --foreground-color-secondary: rgb(174, 69, 23);
  --ribbon-color-primary: rgb(254, 112, 50);
  --ribbon-color-secondary: rgb(254, 112, 50);
  --border-color: transparent;
  box-shadow: 0px 0px 0px 1px var(--material-default);
  text-decoration-color: rgb(174, 69, 23);
}

.fc-theme-standard .fc-scrollgrid {
  border: none;
}

.fc-day-today {
  background: transparent !important;
}

.fc .fc-timegrid-slot-minor {
  border-top-style: hidden;
}

.fc .fc-timegrid-slot-label {
  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 500;
  font-size: 10px;
  line-height: 11px;
  letter-spacing: -0.04em;
  text-align: right;
  font-feature-settings: "tnum", "lnum", "ss01", "ss02", "ss03", "ss04";
  color: var(--calendar-sidebar-time-color);
  text-transform: uppercase;
}

.fc-theme-standard td {
  border: 0.5px solid var(--calendar-border-color);
  border-right: none;
}

.fc-theme-standard th {
  border: none;
  border-bottom: 0.5px solid var(--calendar-border-color);
}

.calendar-header-custom {
  font-size: 14px;
  padding: 3px 0px;
  color: var(--calendar-header-color);
  font-weight: 600;
  /* font-family: Avenir Next; */
  display: flex;
  align-items: center;
}

.calendar-header-custom .today {
  color: #1d80ff;
  margin-left: 5px;
  font-size: 11px;
}

.event-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-family: var(--sans-serif-font);
  font-weight: 500;
  padding: 3px 0px;
}

.event-content.not-attending {
  color: var(--text-secondary);
  text-decoration: line-through;
}

.event-content.small-text {
  padding: 0px;
  font-size: 10px;
  font-weight: 600;
}

.event-meta {
  margin-right: 4px;
  row-gap: 2px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.event-title {
  /* vertical-align: top; */
  /* display: inline; */
  vertical-align: text-bottom;
  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 12px;
  line-height: 15px;
  /* font-weight: 700; */
}

.event-content.small-text .event-title {
  font-size: 10px;
}

.event-time {
  font-size: 10px;
  font-weight: 400;
}

.event-title.completed {
  text-decoration: line-through;
}

.event-complete-check {
  flex: 0 0 15px;
  height: 15px;
  color: white;
}

.event-complete-check.small {
  flex: 0 0 10px;
  height: 10px;
  color: white;
}

.event-complete-check-xsmall {
  flex: 0 0 10px;
  height: 12px;
  margin-top: 2px;
  color: white;
  padding-right: 1px
}

.show-more-events {
  cursor: pointer;
  color: #007bff;
  font-size: 14px;
  margin-top: 5px;
}

.fc-timegrid-event .fc-event-main {
  padding: 1px 1px 0;
  overflow: hidden;
}

.calendar-settings-bar {
  border-bottom: var(--topbar-border-bottom);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.calendar-settings-icon {
  height: 17px;
  width: 17px;
  color: var(--topbar-button-secondary-color);
  cursor: pointer;
}

.last-sync-text {
  font-size: 13px;
  color: #7e7e7e;
  font-weight: 500;
}

.add-calendar-text-small {
  font-size: 13px;
  color: #7e7e7e;
  font-weight: 500;
  padding: 5px 9px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  margin-top: 5px;
}

.add-calendar-text-small .icon {
  margin-right: 5px;
  height: 13px;
  width: 13px;
}

.add-calendar-text-small:hover {
  background-color: var(--option-hover-background-color);
}

.calendar-list-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 9px;
  font-size: 14px;
  /* margin-bottom: 2px; */
}

.calendar-list-item:hover {
  background-color: var(--option-hover-background-color);
}

.calendar-list-item-checkbox {
  /* height: 10px; */
  color: white;
  margin-right: 8px;
  padding: 6px;
  border: 2px solid;
  border-radius: 4px;
  cursor: pointer;
}

.calendar-account {
  padding: 5px 6px;
  font-size: 14px;
  color: #646464;
  font-weight: 600;
}

.calendar-settings-title {
  padding: 0px 0px 0px 9px;
  font-size: 15px;
  color: var(--text-primary);
  font-weight: 600;
}

.calendar-settings-container {
  padding: 10px 6px;
}

.calendar-settings-content {
  max-height: 450px;
  overflow-y: auto;
}

.context-mmenu-row-icon.logo {
  margin-right: 10px;
  width: 15px;
  height: auto;
}

.last-sync-expanded {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.last-sync-text {
  display: flex;
  align-items: center;
  color: #b4b4b4;
  cursor: pointer;
}

.last-sync-icon {
  margin-right: 4px;
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }

  to {
    transform: scale(1) rotate(360deg);
  }
}

.last-sync-icon-container {
  padding: 5px;
  border-radius: 50%;
  color: var(--topbar-button-secondary-color);
  cursor: pointer;
}

.last-sync-icon-container.expanded {
  border-radius: 10px;
  padding: 5px 5px;
}

.last-sync-icon-container:hover {
  background: var(--topbar-button-hover-background-color);
}

.last-sync-icon-container.expanded:hover {
  background: none;
  border-radius: 10px;
  padding: 5px 5px;
  color: var(--text-primary);
}

/* Make icon black when parent  container is hovered */
.last-sync-icon-container.expanded:hover .last-sync-icon {
  color: var(--text-primary);
}

.last-sync-icon-container.expanded:hover .last-sync-text {
  color: var(--text-primary);
}

.mini-toolbar-calendar .last-sync-icon {
  margin-right: 0px;
}

.last-sync-icon.expanded {
  color: #b4b4b4;
}

.last-sync-icon.animated {
  animation: spin 1s infinite linear;
}

.last-sync-text:hover {
  color: var(--text-primary);
}

.integration-preview {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
  border-radius: 10px;
}

.integration-image {
  height: 265px;
  border-radius: 10px;
  margin-right: 10px;
}

.integration-title {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-primary);
  padding: 25px;
}

.add-calendar-modal {
  padding: 25px;
}

.add-calendar-container.primary {
  padding: 17px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  margin-top: 10px;
  border: 1px solid var(--border-color);
  background-color: transparent;
  cursor: initial;
}

.add-calendar-container.primary .add-calendar-text {
  font-size: 14px;
  font-weight: 600;
  color: var(--text-primary);
  display: flex;
  align-items: center;
}

.ios-chip {
  background-color: var(--message-background);
  padding: 4px 11px;
  border-radius: 6px;
  font-size: 12px;
  color: var(--text-primary);
  margin-left: 10px;
  -webkit-user-select: none;
  user-select: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.weekview-container {
  height: 100%;
  width: 100%;
  background-color: white;
}

.w-full.max-h-72.overflow-auto.pr-1::-webkit-scrollbar {
  width: 5px; /* width of the scrollbar */
}

.w-full.max-h-72.overflow-auto.pr-1::-webkit-scrollbar-thumb {
  background: #d7d7d7; /* color of the scrollbar thumb */
}

.w-full.max-h-72.overflow-auto.pr-1::-webkit-scrollbar-track {
  background: #ffffff; /* color of the scrollbar track */
}

.calendar-header-today {
  background: #6b00ff;
  padding: 1px 4px;
  border-radius: 6px;
  color: #ffffff;
}

.floating-toolbar-calendar {
  position: absolute;
  bottom: 30px;
  right: 40px;
  align-items: end;
  color: var(--text-primary);
  display: flex;
  flex-direction: column;
  z-index: 10000;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: row;
  column-gap: 7px;
}

.calendar-toolbar {
  padding: 5px 8px;
  background: white;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  display: flex;
  box-shadow: 0 0 6px 0 #ce646442;
  align-items: center;
  column-gap: 6px;
}

.calendar-settings-inner {
  padding: 10px;
}

/* Send updates modal */
.send-updates-modal {
  padding: 25px;
}

.send-updates-modal .title {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 10px;
}

.send-updates-modal .description {
  color: var(--text-secondary);
}

.send-updates-modal .footer-buttons {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
}

.send-updates-modal .thin-button.secondary {
  color: var(--text-secondary);
}

.send-updates-modal .thin-button.secondary:hover {
  color: var(--text-primary);
}

.footer-buttons .right-side {
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.calendar-account-option:hover .calendar-account-dropdown {
  visibility: visible;
}

.calendar-account-dropdown {
  visibility: hidden;
}

.completed-task {
  opacity: 0.5 !important;
}

.preview-task {
  opacity: 1 !important;
}

.less-padding {
  padding: 2px 4px 1px 5px !important;
}