.card-container {
    padding: 0.75em;
    border-radius: 10px;
    background-color: var(--card-background-color);
    border: 1px solid var(--card-border-color);
    box-shadow: var(--card-box-shadow);
    font-size: .85em;
    transition: opacity .2s;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    margin-bottom: 10px;
    min-width: 245px;
    padding: 11px 12px;
}

.card-container-create {
    padding: 0.75em;
    border-radius: 10px;
    background-color: var(--card-background-color);
    border: 1px solid var(--card-border-color);
    box-shadow: var(--card-box-shadow);
    font-size: .85em;
    transition: opacity .2s;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    margin-bottom: 10px;
    min-width: 245px;
    padding: 11px 12px;
}

.card-container.activelySelected {
    box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
    opacity: 1 !important;
    border: 1px solid var(--actively-selected-border-color);
}

.card-container.preview {
    border: 2px solid #0071ffa6;
}


/*
.card-container:hover {
    box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
} */

.card-container:focus-within {
    box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
}

.draggable-card-container {
    padding: 0.25em 0em;
    cursor: grab;
}

.card-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
}

.card-description-non-editable {
    appearance: none;
    background-color: initial;
    background-image: none;
    border-radius: 3px;
    border-style: none;
    box-shadow: none;
    color: var(--text-primary);
    display: block;
    line-height: 20px;
    font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
    outline: none;
    overflow: auto;
    overflow: hidden;
    overflow-wrap: break-word;
    padding: 0;
    resize: none;
    transition-duration: 85ms;
    transition-property: background-color, border-color, box-shadow;
    transition-timing-function: ease;
    width: 100%;
    margin-right: 15px;
    padding: 5px;
    border: 2px solid transparent;
}


.card-description {
    -webkit-appearance: none;
    appearance: none;
    background-color: initial;
    background-image: none;
    border-radius: 3px;
    border-style: none;
    box-shadow: none;
    color: var(--text-primary);
    display: block;
    line-height: 18px;
    font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
    outline: none;
    overflow: hidden;
    overflow-wrap: break-word;
    resize: none;
    transition-duration: 85ms;
    transition-property: background-color, border-color, box-shadow;
    transition-timing-function: ease;
    width: 100%;
    /* margin-right: 15px; */
    /* padding: 5px; */
    /* border: 2px solid transparent; */
    margin-top: 0px;
    margin-left: 3px;
    margin-right: 8px;
    padding: 3px 6px;
    font-size: 12px;

    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;
    word-break: break-word;
    /* max-width: 265px; */
    font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol"
}

.card-description.editable {
    border: 1.5px dashed transparent;
    padding: 5px;
    cursor: text;
    margin-left: 0px;
}

.card-description:hover {
    border: 1.5px dashed #b359ff;
    padding: 5px;
    border-radius: 5px;
    cursor: text;
    margin-left: 0px;
}

.card-description.no-hover {
    padding: 3px 6px;
    border: none !important;
    background: transparent !important;
    cursor: pointer;
    margin-left: 3px;
}

.card-description:focus {
    /* background: #f3f3f3; */
    border-radius: 5px;
    border: 1.5px dashed #b359ff;
    padding: 5px;
    cursor: text;
    margin-left: 0px;
}

.card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 26px;
}

.card-footer.creation {
    padding-left: 4px;
    padding-top: 5px;
}

.card-left-buttons {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
}


.card-time-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding-top: 5px;
    margin-right: -0.75em;
    margin-left: -0.75em;
    padding-left: 0.75em;
    padding-right: 0.75em;
    border-top: 1px solid var(--border-color);
}

.card-container.complete {
    opacity: .5;
}

/*
.card-container.complete:hover {
    opacity: 1;
} */

#card-context-menu .ant-popover-inner-content {}

.ant-popover-inner-content {
    border-radius: 5px;
}

.card-context-menu {
    min-width: 150px;
    color: var(--text-primary);
    font-weight: 500;
}

.context-menu-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 6px;
    font-size: 14px;
}

.context-menu-row:hover {
    color: var(--text-primary);
    background-color: var(--button-hover-background-color);
}

.context-mmenu-row-icon {
    height: 18px;
    margin-right: 5px;
}


@media only screen and (max-width: 768px) {
    .card-description {
        font-size: 16px;
        line-height: 23px;
    }

    .card-container {
        font-size: 16px;
        padding: 15px;
    }

    .card-container-create {
        font-size: 16px;
        padding: 15px;
    }

    .card-header {
        margin-bottom: 10px;
    }
}

.subtask-button.repeat {
    width: 15px;
    height: 15px;
    margin-right: 0px;
}

.subtask-button.notes {
    width: 15px;
    height: 15px;
    margin-right: 0px;
}

.subtask-button {
    /* color: green; */
    height: 12px;
    /* fill: green; */
    width: 12px;
    /* stroke-width: 100px; */
    /* stroke: red; */
    margin-right: 3px;
    color: var(--text-secondary);
}

.notes-button-container {
    display: none;
    font-size: 12px;
}



.subtask-button:hover {
    border-color: #2683ff;
    color: #2683ff;
}

.search-item-container .subtask-button:hover {
    border-color: var(--text-secondary);
    color: var(--text-secondary);
}

.subtask-button-container {
    display: none !important;
    font-size: 12px;
}

.notes-button-container.visible {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: var(--text-secondary);
}

.subtask-button-container.visible {
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: var(--text-secondary);
}

.subtask-button-container:hover {
    color: #2683ff;
}

.search-item-container .subtask-button-container:hover {
    color: var(--text-secondary);
}

.card-container:hover .subtask-button-container {
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: var(--text-secondary);
    cursor: pointer;
}

.card-container:hover .subtask-button-container:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: #2683ff;
    cursor: pointer;
}

.card-drag-preview {
    width: 265px;
    opacity: 0.6;
    cursor: grab;
}

.header-left {
    display: flex;
    flex-direction: column;
}

.task-time {
    /* background-color: #f7f7f7; */
    font-size: 11px;
    padding: 0px 2px;
    border-radius: 5px;
    /* display: inline-flex; */
    font-weight: 600;
    color: #a6a6a6;
}

/* Mobile */
@media only screen and (max-width: 768px) {
    .card-footer {
        padding-left: 35px;
    }
}

.stacked-card {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: bottom center;
  }